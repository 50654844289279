@tailwind base;
@tailwind components;
@tailwind utilities;

.react-flow__handle{
    opacity: 0;
  }
  
.react-flow__panel a {
    opacity: 0
}

.markdown {
    font-size: 14px;
}
  
.markdown table {
    width: 100%;
    overflow-x: auto;
    border-collapse: collapse;
    text-align: left;
    display: block;
}

.markdown thead, tbody {
    background: white;
    border: 1px solid rgb(190,190,190);
}

.markdown th, td {
    padding: 0.5rem 1rem !important;
}

.markdown thead td th:not(last-of-type){
    border-right: 1px solid rgb(190,190,190);
}

.markdown tbody tr {
    border-top: 1px solid rgb(190,190,190);
}

.markdown tbody tr td:not(last-of-type) {
    border-right: 1px solid rgb(190,190,190);
}

.markdown a {
    text-decoration: none;
    color: #1e40af;
    word-wrap: break-word;
}

.markdown a:hover {
    text-decoration: underline;
}

.tooltip {
    @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
    @apply visible z-100;
}